import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";

import Layout from "/src/components/Layout.js";
import Banner from "/src/components/Banner.js";
import BlogRoll from "/src/components/BlogRoll.js";
import Scroller from "../components/Scroller";

export default class BlogIndexPage extends React.Component {
  render() {
    const tags = this.props.data.allMarkdownRemark.group;

    return (
      <Layout>
        <Helmet>
          <script
            src="https://kit.fontawesome.com/af3bcee49a.js"
            crossorigin="anonymous"
          ></script>

          <script
            type="application/javascript"
            async
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UAek8u"
          ></script>

          <link
            rel="stylesheet"
            type="text/css"
            href="https://use.typekit.net/hpw3pei.css"
          />
        </Helmet>
        <Banner />
        <div className="container">
          <p className="call-out">what do you want to read about today?</p>
        </div>
        <div>
          <section
            className="section"
            style={{
              padding: "0 1.5rem 3rem",
              width: "100%",
            }}
          >
            <div className="container">
              <ul className="penny-tags">
                {tags.map((tag) => (
                  <li key={tag.fieldValue}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                      {tag.fieldValue}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="content">
                <BlogRoll />
              </div>
            </div>
          </section>
        </div>
        <Scroller />
        <div
          style={{
            textAlign: "center",
            margin: "25px",
            padding: "5px",
          }}
        >
          <a
            className="button-secondary | button "
            href="https://penny-finance.com"
          >
            take me to penny
          </a>
        </div>
      </Layout>
    );
  }
}

export const tagQuery = graphql`
  query TAG_QUERY {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
