import React from 'react';

const Banner = () => {
  return <section className="banner-wrapper ">
   
    <div
      className="container banner"
    >
       <img
            className="banner-image"
            alt="Banner Image"
            src="/img/home/banner.jpg"
          />
       
      <h2 className="banner-heading">penny <div>blog</div></h2>
    </div>
  </section>
  ;
};


export default Banner;
